import React, { useContext } from 'react';
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import MainPage from './screens/main';

function getRoutes({apiToken, loginSuccess, dispatch}) {

  console.log("get routes called")

  return (
      <Router>
          <Route exact path="/" component={MainPage} />
      </Router>
  );
}

const mapStateToProps = (state) => {
  return {
  }
}

export default connect(mapStateToProps)(getRoutes)
