import { createStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';

// Colors used that are not in this scheme are marked with NOT_STANDARD_COLOR annotation
export const theme_1_colors = {intro:'#ab2831', primary: '#a93e45', detail: '#100C3E', bg: '#E4E4D2', detail2: '#0f0e38', gray: 'lightgray', white: '#fff', overlay: '#303030', success: 'green', black: '#000', verified: '#76ba1b'};

// Electric
const color1 = '#7ef9ff'//'#a93e45';
const color2 = '#000';

export const theme_2_colors = {intro: color1, primary: color2, detail: color2, bg: color2, detail2: color1, gray: '#d3d3d3', white: color1, overlay: '#303030', success: color1, black: color2, verified: '#76ba1b', bars: '#fff', modal: '#E4E4D2', real_white: '#fff', error: '#ab2831'};

export const theme_colors = theme_2_colors;

const theme = createMuiTheme({
  palette: {
    primary: {
        main: color1
    },
    secondary: {
        main: color2
    },
  },
  status: {
    danger: '#E4E4D2',
  },
});
