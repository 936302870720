import {

} from '../actions/types'

import React from 'react';

const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
    default:
      return { ...state };
  }
}
