import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
} from '@material-ui/core'

import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import Lottie from 'react-lottie';
import * as animationData from '../assets/animations/brain_anim.json'

import {theme_colors} from '../tempfile'
//import {} from '../../actions'

const logo = require('../assets/images/brand_transparent.png')

const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
  };

class MainPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isStopped: false,
      isPaused: false,
      lottieHeight: 1800,
      lottieSpeed: 0.1,
      numAnims: 5
    };


  }


  componentWillMount(){
    this.setState({},_=>this.setSpeed())
  }
  setSpeed(){
    this.setState({speed: 0.1})
  }

  componentDidMount(){
    scroll.scrollToTop();
    setTimeout(() => {
      scroll.scrollTo(this.state.lottieHeight*this.state.numAnims, {
          duration: 60000,
          delay: 0,
          smooth: true,
      })
    }, 3000)
  }

  render(){

    let background_anims = []

    for(let i = 0; i < this.state.numAnims; i++){
      background_anims.push(
      <Lottie options={defaultLottieOptions}
          height={this.state.lottieHeight}
          width={this.state.lottieHeight}
          speed={this.state.lottieSpeed}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
          isClickToPauseDisabled={true}
          style={{
            position:'absolute',
            top: i + '00%',
            left: '-10%',
            marginTop: i > 0 ? 400 : 0
          }}
      />);
    }


    return (
        <div>
        <div style={styles.container}>
          <AppBar position="static" style={{backgroundColor: 'transparent'}}>
            <Toolbar>
              <Typography variant="h6">
                Coming Soon
              </Typography>
              <Button style={styles.button}>Promo</Button>
            </Toolbar>
          </AppBar>
          <div style={styles.title_cont}>
            <Typography style={styles.title} variant="h3">
              Take a
            </Typography>
            <img
              src={logo}
              style={styles.logo}
              alt=""
            />
            <Typography style={styles.descr} variant="h4">
              A new era of dating has arrived.
            </Typography>
          </div>
        </div>
        {background_anims}
      </div>
    );
  }
};

const styles = {
  container: {
    position: 'fixed',
    width:'100%',
    height:'100%',
    top:'0px',
    left:'0px',
    zindex:'1000',
    backgroundColor: theme_colors.black
  },
  title_cont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'column'
  },
  logo: {
    width: '50%',
    alignSelf: 'center'
  },
  title: {
    marginTop: 100,
    color: theme_colors.white,
    textAlign: 'center'
  },
  descr: {
    marginTop: 20,
    color: theme_colors.white,
    width: '50%',
    textAlign: 'center'
  },
  button: {
    color: theme_colors.white,
    borderRadius: 10,
    borderColor: theme_colors.white,
    borderWidth: 2
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

export default withRouter(connect(
  mapStateToProps,
  {}
)(MainPage))
